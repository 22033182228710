import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import TableList from './components/TableList';
import Button from '../../components/Button';
import TableListSkeleton from './components/TableListSkeleton';

import { Container, ContainerRow, ContainerFlex } from './styles';

import api from '../../services/api';

export default function Investiments() {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [investiments, setInvestiments] = useState([]);
  const [realStateFunds, setRealStateFunds] = useState([]);
  const [stockExchange, setStockExchange] = useState([]);

  useEffect(() => {
    loadInvestiments();
  }, []);

  async function loadInvestiments() {
    setLoading(true);

    try {
      const response = await api.get('/investiments');

      const realStateFunds = response.data.filter((i) => i.category_id == 1);
      const stockExchange = response.data.filter((i) => i.category_id == 2);

      setInvestiments(response.data);
      setRealStateFunds(realStateFunds);
      setStockExchange(stockExchange);
    } catch (error) {
      toast.error('Erro ao buscar os dividendos');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      {loading && <TableListSkeleton title="Ações" />}
      {loading && <TableListSkeleton title="Fundos Imobiliários" />}

      {!loading && <TableList title="Ações" data={stockExchange} />}
      {!loading && (
        <TableList title="Fundos Imobiliários" data={realStateFunds} />
      )}

      <br />
      <br />

      <ContainerRow>
        <ContainerFlex />
        <div style={{ width: 20 }} />
        <ContainerFlex>
          <Button text="Voltar" onPress={() => navigation(-1)} />
        </ContainerFlex>
      </ContainerRow>
      <div style={{ height: 100 }} />
    </Container>
  );
}
