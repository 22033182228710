import React from 'react';
import {
  MdArrowDownward,
  MdArrowUpward
} from 'react-icons/md';

import { Container, Table, Tr, Th, Td } from './styles';
import { colors } from '../../../../commonStyle';

import { formatMoney } from '../../../../helpers';

export default function TableList({ title = '', data = [] }) {
  //(Preço atual / Preço anterior) x 100 – 100
  function rendimentPercent(cotation, price) {
    const rendiment = ((cotation / price) * 100 - 100).toFixed(2);
    return (
      <Td color={rendiment > 0.0 ? colors.primary : colors.red}>
        <div style={{textAlign: 'left', position: 'absolute'}}>
          {getIconArrow(rendiment)}
        </div>
          {rendiment}%
      </Td>
    );
  }

  function rendimentPrice(cotation, price) {
    const rendiment = (cotation - price).toFixed(2);
    return (
      <Td color={rendiment > 0.0 ? colors.primary : colors.red}>
        {formatMoney(rendiment)}
      </Td>
    );
  }

  function rendimentTotal(amount, cotation, price_total) {
    const rendimentCurrent = amount * cotation;
    const rendiment = (rendimentCurrent - price_total).toFixed(2);
    return (
      <Td color={rendiment > 0.0 ? colors.primary : colors.red}>
        {formatMoney(rendiment)}
      </Td>
    );
  }

  function getIconArrow(rendiment) {
    return rendiment > 0.0 ? <MdArrowUpward size={18} color={colors.primary} /> : <MdArrowDownward size={18} color={colors.red} />;
  }

  return (
    <Container>
      <div style={{ height: 30 }} />
      <h1>{title}</h1>
      <div style={{ height: 10 }} />
      <Table>
        <Tr>
          <Th width="11%">Ativo</Th>
          <Th width="11%">Quantidade</Th>
          <Th width="11%">Média</Th>
          <Th width="11%">Cotação</Th>
          <Th width="11%">Rendimento (%)</Th>
          <Th width="11%">Rendimento (R$)</Th>
          <Th width="11%">Total Compra</Th>
          <Th width="11%">Total Atualizado</Th>
          <Th width="11%">Total Rendimento</Th>
        </Tr>
        {data.map((i, index) => (
          <Tr index={index}>
            <Td>{i.active}</Td>
            <Td>{i.amount}</Td>
            <Td>{formatMoney(i.price)}</Td>
            <Td>{formatMoney(i.cotation)}</Td>
            {rendimentPercent(i.cotation, i.price)}
            {rendimentPrice(i.cotation, i.price)}
            <Td>{formatMoney(i.price_total)}</Td>
            <Td>{formatMoney(i.amount * i.cotation)}</Td>
            {rendimentTotal(i.amount, i.cotation, i.price_total)}
          </Tr>
        ))}
        <Tr index={data.length}>
          <Td>&nbsp;</Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </Tr>
        <Tr index={data.length + 1}>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td color={colors.red} bold>
            Total
          </Td>
          <Td color={colors.red} bold>
            {formatMoney(data.reduce((t, i) => t + i.price_total, 0))}
          </Td>
          <Td color={colors.red} bold>
            {formatMoney(data.reduce((t, i) => t + i.amount * i.cotation, 0))}
          </Td>
          <Td color={colors.red} bold>
            {formatMoney(
              data.reduce(
                (t, i) => t + (i.amount * i.cotation - i.price_total),
                0
              )
            )}
          </Td>
        </Tr>
      </Table>
    </Container>
  );
}
