import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import TableList from './components/TableList';
import Button from '../../components/Button';
import Input from '../../components/Input';
import Select from '../../components/Select';
import TableListSkeleton from './components/TableListSkeleton';

import { Container, ContainerRow, ContainerFlex } from './styles';

import api from '../../services/api';
import typeTransactionList from '../../services/typeTransactionList.json';

export default function Transactions() {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [actives, setActives] = useState([]);
  const [activeId, setActiveId] = useState(null);

  const [transactions, setTransactions] = useState([]);
  const [transactionsCopy, setTransactionsCopy] = useState([]);

  useEffect(() => {
    loadTransactions();
    loadActives();
  }, []);

  async function loadActives() {
    setLoading(true);

    try {
      const response = await api.get('/active');

      const activesNamed = response.data?.map((item) => ({
        ...item,
        name: item.code,
        code: item.name,
      }));
      setActives(activesNamed);
    } catch (error) {
      //alert('Erro ao buscar os ativos');
      toast.error('Erro ao buscar os ativos.');
      navigation(-1);
    } finally {
      setLoading(false);
    }
  }

  async function loadTransactions() {
    setLoading(true);

    try {
      const response = await api.get('/transactions');

      setTransactions(response.data);
      setTransactionsCopy(response.data);
    } catch (error) {
      toast.error('Erro ao buscar as transações.');
    } finally {
      setLoading(false);
    }
  }

  function onChangeFilterActive(value) {
    if (typeId !== null) setTypeId(null);

    if (value === '-') {
      return setTransactions(transactionsCopy);
    }

    const transactionsFiltered = transactionsCopy.filter(
      (i) => i.active_id == value
    );
    setTransactions(transactionsFiltered);
  }

  function onChangeFilterTypeTransaction(value) {
    if (activeId !== null) setActiveId(null);

    if (value === '-') {
      return setTransactions(transactionsCopy);
    }

    const transactionsFiltered = transactionsCopy.filter(
      (i) => i.type == value
    );
    setTransactions(transactionsFiltered);
  }

  function handleFilterDates() {
    if (startDate !== null && endDate !== null) {
      const filtered = transactionsCopy.filter((item) => {
        return (
          new Date(item.date).getTime() >= new Date(startDate).getTime() &&
          new Date(item.date).getTime() <= new Date(endDate).getTime()
        );
      });

      setTransactions(filtered);
    } else {
      alert('Você deve selecionar duas datas');
    }
  }

  function onCleanFilters() {
    setTransactions(transactionsCopy);
    setStartDate(null);
    setEndDate(null);

    document.getElementById('startDate').value = null;
    document.getElementById('endDate').value = null;
  }

  return (
    <Container>
      <br />
      <h1>Transações</h1>
      <div style={{ height: 10 }} />

      <ContainerRow>
        <Input
          label="Data de:"
          type="date"
          id="startDate"
          value={startDate}
          onChange={setStartDate}
        />
        <div style={{ width: 20 }} />
        <Input
          label="Data até:"
          type="date"
          id="endDate"
          value={endDate}
          onChange={setEndDate}
        />
        <div style={{ width: 20 }} />
        <div style={{ minWidth: 120 }}>
          <Button text="Filtrar" onPress={handleFilterDates} />
        </div>
        <div style={{ minWidth: 120 }}>
          <Button text="Limpar" onPress={onCleanFilters} />
        </div>
      </ContainerRow>
      <ContainerRow>
        <Select
          label="Tipo:"
          items={typeTransactionList}
          selected={typeId}
          onChange={(e) => {
            setTypeId(e);
            onChangeFilterTypeTransaction(e);
          }}
        />
        <div style={{ width: 20 }} />
        <Select
          label="Ativo:"
          items={actives}
          selected={activeId}
          onChange={(e) => {
            setActiveId(e);
            onChangeFilterActive(e);
          }}
        />
      </ContainerRow>

      {loading && <TableListSkeleton title="Ultimas transações" />}
      {!loading && <TableList title="Ultimas transações" data={transactions} />}

      <br />
      <br />

      <ContainerRow>
        <ContainerFlex>
          <Button
            text="Nova Transação"
            onPress={() => navigation('/transactions/new')}
          />
        </ContainerFlex>
        <div style={{ width: 20 }} />
        <ContainerFlex>
          <Button text="Voltar" onPress={() => navigation(-1)} />
        </ContainerFlex>
      </ContainerRow>
      <div style={{ height: 100 }} />
    </Container>
  );
}
