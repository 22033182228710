import React from 'react';
import {
  BrowserRouter,
  Routes as RoutesComponent,
  Route,
} from 'react-router-dom';

import Login from '../pages/Login';

export default function SignInRoutes() {
  return (
    <BrowserRouter>
      <RoutesComponent>
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
      </RoutesComponent>
    </BrowserRouter>
  );
}
