import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Container, Table, Tr, Th, Td } from '../TableList/styles';
import { colors } from '../../../../commonStyle';

export default function TableListSkeleton({ title = '', data = [1, 2, 3] }) {
  return (
    <Container>
      <div style={{ height: 30 }} />
      <h1>{title}</h1>
      <div style={{ height: 5 }} />
      <Table>
        <Tr>
          <Th width="16%">Data</Th>
          <Th width="14%">Operação</Th>
          <Th width="14%">Ativo</Th>
          <Th width="14%">Quantidade</Th>
          <Th width="14%">Preço</Th>
          <Th width="14%">Taxas</Th>
          <Th width="14%">Total</Th>
        </Tr>
        {data.map((_, index) => (
          <Tr index={index}>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
          </Tr>
        ))}
        {/* <Tr index={data.length}>
          <Td>&nbsp;</Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </Tr> */}
        {/* <Tr index={data.length + 1}>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td color={colors.red} bold>
            Total
          </Td>
          <Td color={colors.red} bold>
            <Skeleton />
          </Td>
        </Tr> */}
      </Table>
    </Container>
  );
}
