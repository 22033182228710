import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import TableList from './components/TableList';
import Button from '../../components/Button';
import TableListSkeleton from './components/TableListSkeleton';

import { Container, ContainerRow, ContainerFlex } from './styles';

import api from '../../services/api';

export default function Cotations() {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingUpdateCotation, setLoadingUpdateCotation] = useState(false);
  const [actives, setActives] = useState([]);
  const [realStateFunds, setRealStateFunds] = useState([]);
  const [stockExchange, setStockExchange] = useState([]);

  useEffect(() => {
    loadActives();
  }, []);

  async function loadActives() {
    setLoading(true);

    try {
      const response = await api.get('/active');

      const realStateFunds = response.data.filter((i) => i.category_id == 1);
      const stockExchange = response.data.filter((i) => i.category_id == 2);

      setActives(response.data);
      setRealStateFunds(realStateFunds);
      setStockExchange(stockExchange);
    } catch (error) {
      toast.error('Erro ao buscar os ativos.');
      navigation(-1);
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateCotations() {
    setLoadingUpdateCotation(true);

    try {
      await api.get('/update_cotation');

      loadActives();
    } catch (error) {
      toast.error('Erro na atualização automática de cotações.');
      navigation(-1);
    } finally {
      setLoadingUpdateCotation(false);
    }
  }

  async function handleUpdateCotationsYahoo() {
    setLoadingUpdateCotation(true);

    try {
      await api.get('/update_cotation/new');

      loadActives();
    } catch (error) {
      toast.error('Erro na atualização automática de cotações.');
      navigation(-1);
    } finally {
      setLoadingUpdateCotation(false);
    }
  }

  return (
    <Container>
      {loading && <TableListSkeleton title="Ações" />}
      {loading && <TableListSkeleton title="Fundos Imobiliários" />}

      {!loading && <TableList title="Ações" data={stockExchange} />}
      {!loading && (
        <TableList title="Fundos Imobiliários" data={realStateFunds} />
      )}

      <br />
      <br />
      <br />

      <ContainerRow>
        <ContainerFlex>
          <Button
            text="Atualizar cotações (Manual)"
            onPress={() => navigation('/cotations/edit')}
          />
        </ContainerFlex>
        {/* <div style={{ width: 20 }} />
        <ContainerFlex>
          <Button
            loading={loadingUpdateCotation}
            text="Atualizar cotações (OLD)"
            onPress={handleUpdateCotations}
          />
        </ContainerFlex> */}
        <div style={{ width: 20 }} />
        <ContainerFlex>
          <Button
            loading={loadingUpdateCotation}
            text="Atualizar cotações (Yahoo)"
            onPress={handleUpdateCotationsYahoo}
          />
        </ContainerFlex>
        <div style={{ width: 20 }} />
        <ContainerFlex>
          <Button text="Voltar" onPress={() => navigation(-1)} />
        </ContainerFlex>
      </ContainerRow>

      <div style={{ height: 100 }} />
    </Container>
  );
}
