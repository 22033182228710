import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import Button from '../../components/Button';
import Select from '../../components/Select';
import TableList from './components/TableList';
import TableListSkeleton from './components/TableListSkeleton';

import { Container, ContainerRow, ContainerFlex } from './styles';

import api from '../../services/api';
import monthList from '../../services/monthList.json';

export default function Dividends() {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState(null);
  const [total, setTotal] = useState(0.0);
  const [dividends, setDividends] = useState([]);

  const yearList = [
    {
      id: moment().subtract('years', 3).format('YYYY'),
      name: moment().subtract('years', 3).format('YYYY'),
    },
    {
      id: moment().subtract('years', 2).format('YYYY'),
      name: moment().subtract('years', 2).format('YYYY'),
    },
    {
      id: moment().subtract('years', 1).format('YYYY'),
      name: moment().subtract('years', 1).format('YYYY'),
    },
    { id: moment().format('YYYY'), name: moment().format('YYYY') },
    {
      id: moment().add('years', 1).format('YYYY'),
      name: moment().add('years', 1).format('YYYY'),
    },
  ];

  const [periodMonth, setPeriodMonth] = useState(moment().format('MM'));
  const [periodYear, setPeriodYear] = useState(moment().format('YYYY'));

  useEffect(() => {
    loadDividends();
  }, []);

  async function loadDividends(month = periodMonth, year = periodYear) {
    setLoading(true);

    try {
      const response = await api.get(`/dividends?period=${month}-${year}`);

      setPeriod(response.data.period);
      setTotal(response.data.total);
      setDividends(response.data.dividends);
    } catch (error) {
      toast.error('Erro ao buscar os dividendos');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <br />

      <h1>Dividendos</h1>

      <div style={{ height: 10 }} />
      <ContainerRow style={{ marginBottom: 20 }}>
        <Select
          label="Mês:"
          disabledNull
          items={monthList}
          selected={periodMonth}
          onChange={(e) => {
            setPeriodMonth(e);
            loadDividends(e, periodYear);
          }}
        />

        <div style={{ width: 20 }} />
        <Select
          label="Ano:"
          disabledNull
          items={yearList}
          selected={periodYear}
          onChange={(e) => {
            setPeriodYear(e);
            loadDividends(periodMonth, e);
          }}
        />
      </ContainerRow>

      {loading && <TableListSkeleton />}
      {!loading && <TableList data={dividends} total={total} />}

      <br />
      <br />

      <ContainerRow>
        <ContainerFlex>
          <Button
            text="Cadastrar dividendos"
            onPress={() => navigation('/dividends/new')}
          />
        </ContainerFlex>
        <div style={{ width: 20 }} />
        <ContainerFlex>
          <Button text="Voltar" onPress={() => navigation(-1)} />
        </ContainerFlex>
      </ContainerRow>
    </Container>
  );
}
