import React from 'react';
import moment from 'moment';

import { Container, Table, Tr, Th, Td } from './styles';
import { colors } from '../../../../commonStyle';

import { formatMoney } from '../../../../helpers';

export default function TableList({ title = '', data = [] }) {
  function typeTransaction(value) {
    return (
      <Td color={value === 1 ? colors.primary : colors.red}>
        {value === 1 ? 'COMPRA' : 'VENDA'}
      </Td>
    );
  }

  return (
    <Container>
      <div style={{ height: 30 }} />
      <h1>{title}</h1>
      <div style={{ height: 10 }} />
      <Table>
        <Tr>
          <Th width="16%">Data</Th>
          <Th width="14%">Operação</Th>
          <Th width="14%">Ativo</Th>
          <Th width="14%">Quantidade</Th>
          <Th width="14%">Preço</Th>
          <Th width="14%">Taxas</Th>
          <Th width="14%">Total</Th>
        </Tr>
        {data.map((i, index) => (
          <Tr index={index}>
            <Td>{moment(i.date).format('DD/MM/YYYY')}</Td>
            {typeTransaction(i.type)}
            {/* <Td>{i.type === 1 ? 'COMPRA' : 'VENDA'}</Td> */}
            <Td>{i.code}</Td>
            <Td>{i.amount}</Td>
            <Td>{formatMoney(i.price)}</Td>
            <Td>{formatMoney(i.tax)}</Td>
            <Td>
              {formatMoney(i.amount * parseFloat(i.price) + parseFloat(i.tax))}
            </Td>
          </Tr>
        ))}
        {/* <Tr index={data.length}>
          <Td>&nbsp;</Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
        </Tr>
        <Tr index={data.length + 1}>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td></Td>
          <Td color={colors.red} bold>
            Total
          </Td>
          <Td color={colors.red} bold>
            {formatMoney(data.reduce((t, i) => t + i.price_total, 0))}
          </Td>
        </Tr> */}
      </Table>
    </Container>
  );
}
