import styled from 'styled-components';

import { colors } from '../../commonStyle';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  /* background-image: url('/images/background.png');
  background-repeat: no-repeat; */
  /* background-size: cover; */
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40%;
  min-width: 500px;
  padding: 0 40px;
  height: 400px;
  justify-content: center;
  align-items: center;
  border: 1px solix #8888;
  background-color: ${colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
