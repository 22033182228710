import React from 'react';
import {
  BrowserRouter,
  Routes as RoutesComponent,
  Route,
} from 'react-router-dom';

import Home from '../pages/Home';

import Investiments from '../pages/Investiments';

import Dividends from '../pages/Dividends';
import AddDividend from '../pages/AddDividend';

import Transactions from '../pages/Transactions';
import AddTransaction from '../pages/AddTransaction';

import Cotations from '../pages/Cotations';
import EditCotation from '../pages/EditCotation';

export default function SignedRoutes() {
  return (
    <BrowserRouter>
      <RoutesComponent>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />

        <Route path="investiments" element={<Investiments />} />

        <Route path="dividends" element={<Dividends />} />
        <Route path="dividends/new" element={<AddDividend />} />

        <Route path="transactions" element={<Transactions />} />
        <Route path="transactions/new" element={<AddTransaction />} />

        <Route path="cotations" element={<Cotations />} />
        <Route path="cotations/edit" element={<EditCotation />} />
      </RoutesComponent>
    </BrowserRouter>
  );
}
