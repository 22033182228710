import styled from 'styled-components';

import { colors } from '../../commonStyle';

export const Container = styled.button`
  display: flex;
  height: 54px;
  width: 100%;
  background-color: ${(props) => (props.color ? props.color : colors.primary)};
  align-self: stretch;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-top: 5px;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  cursor: pointer;
  border: none;
`;

export const Text = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.white};
`;

export const Spinner = styled.div`
  border: 3px solid rgba(0, 0, 0, 0.5);
  border-left-color: ${(props) => (props.color ? props.color : colors.white)};
  border-radius: 50%;
  width: ${(props) => (props.size ? props.size : 25)}px;
  height: ${(props) => (props.size ? props.size : 25)}px;
  animation: spin 1s linear infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
