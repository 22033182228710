import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Select from '../../components/Select';

import { Container, ContainerRow, ContainerFlex } from './styles';

import api from '../../services/api';
import typeTransactionList from '../../services/typeTransactionList.json';

export default function AddTransaction() {
  const navigation = useNavigate();

  const yearList = [
    {
      id: moment().subtract('years', 2).format('YYYY'),
      name: moment().subtract('years', 2).format('YYYY'),
    },
    {
      id: moment().subtract('years', 1).format('YYYY'),
      name: moment().subtract('years', 1).format('YYYY'),
    },
    { id: moment().format('YYYY'), name: moment().format('YYYY') },
    {
      id: moment().add('years', 1).format('YYYY'),
      name: moment().add('years', 1).format('YYYY'),
    },
    {
      id: moment().add('years', 2).format('YYYY'),
      name: moment().add('years', 2).format('YYYY'),
    },
  ];

  const [loading, setLoading] = useState(false);
  const [actives, setActives] = useState([]);

  const [date, setDate] = useState(moment().format('YYYY-DD-MM'));
  const [type, setType] = useState(1);
  const [activeId, setActiveId] = useState(null);
  const [amount, setAmount] = useState(null);
  const [price, setPrice] = useState(null);
  const [tax, setTax] = useState(null);

  const amountRef = useRef();

  useEffect(() => {
    loadActives();
  }, []);

  async function loadActives() {
    setLoading(true);

    try {
      const response = await api.get('/active/all');

      const activesNamed = response.data?.map((item) => ({
        ...item,
        name: item.code,
        code: item.name,
      }));
      setActives(activesNamed);
    } catch (error) {
      //alert('Erro ao buscar os ativos');
      toast.error('Erro ao buscar os ativos.');
      navigation(-1);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit() {
    if (date == null)
      return toast.error('Necessário informar a data da transação.');
    if (type == null) return toast.error('Necessário informar o tipo.');
    if (activeId == null) return toast.error('Necessário informar o ativo.');
    if (amount == null || amount == '')
      return toast.error('Necessário informar a quatindade.');
    if (price == null || price == '')
      return toast.error('Necessário informar o valor unitário.');
    if (tax == null || tax == '')
      return toast.error('Necessário informar as taxas.');

    setLoading(true);

    try {
      const response = await api.post('/transactions', {
        active_id: activeId,
        amount,
        price,
        tax,
        type,
        date: moment(date).format('YYYY-MM-DD'),
      });

      const { msg } = response.data;
      toast.success(msg);
      clearFields();
    } catch (err) {
      toast.error(err.response.data.msg);
      clearFields();
    } finally {
      setLoading(false);
    }
  }

  function clearFields() {
    setActiveId(null);
    setAmount(null);
    setPrice(null);
    setTax(null);

    document.getElementById('amount').value = null;
    document.getElementById('price').value = null;
    document.getElementById('tax').value = null;
  }

  return (
    <Container>
      <br />
      <h1>Nova Transação</h1>
      <div style={{ height: 20 }} />

      <ContainerRow>
        <ContainerFlex>
          <Input label="Data:" type="date" value={date} onChange={setDate} />
        </ContainerFlex>
        <div style={{ width: 20 }} />
        <ContainerFlex>
          <Select
            label="Tipo:"
            disabledNull
            items={typeTransactionList}
            selected={type}
            onChange={setType}
          />
        </ContainerFlex>
      </ContainerRow>

      <Select
        label="Ativo:"
        items={actives}
        selected={activeId}
        onChange={(e) => {
          amountRef.current.focus();
          setActiveId(e);
        }}
      />

      <ContainerRow>
        <Input
          id="amount"
          type="number"
          ref={amountRef}
          label="Quantidade"
          value={amount}
          onChange={setAmount}
        />

        <div style={{ width: 20 }} />
        <Input
          id="price"
          type="number"
          label="Preço Unitário (R$)"
          value={price}
          onChange={setPrice}
        />

        <div style={{ width: 20 }} />
        <Input
          type="number"
          label="Total (R$)"
          value={(amount * price).toFixed(2)}
          disabled
        />
      </ContainerRow>
      <Input
        id="tax"
        type="number"
        label="Taxas (Emolumentos + Liquidação) (R$)"
        value={tax}
        onChange={setTax}
      />

      <br />
      <br />

      <ContainerRow>
        <Button text="Cadastrar" onPress={handleSubmit} loading={loading} />
        <div style={{ width: 20 }} />
        <Button text="Voltar" onPress={() => navigation(-1)} />
      </ContainerRow>
    </Container>
  );
}
