import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MdAttachMoney,
  MdList,
  MdCompareArrows,
  MdShowChart,
} from 'react-icons/md';
import { toast } from 'react-toastify';

import ButtonHome from '../../components/ButtonHome';
import Button from '../../components/Button';

import { Container, ContainerRow, ContainerFlex } from './styles';
import { colors } from '../../commonStyle';

import { useAuth } from '../../context/auth';

import api from '../../services/api';

export default function Home() {
  const navigation = useNavigate();

  const { user, Logout } = useAuth();

  useEffect(() => {
    loadProfile();
  }, []);

  async function loadProfile() {
    try {
      await api.get('/profile');
    } catch (error) {
      if (error.response.status === 401) {
        toast.error('Seu login expirou, logue novamente.');
        Logout();
        navigation('/');
      }
    }
  }

  return (
    <Container>
      <br />
      <h2>Olá, {user?.name}</h2>
      <br />

      <ContainerRow>
        <ContainerFlex>
          <ButtonHome
            text="Investimentos"
            icon={<MdList size={60} color={colors.white} />}
            onPress={() => navigation('/investiments')}
          />
        </ContainerFlex>
        <div style={{ width: 20 }} />
        <ContainerFlex>
          <ButtonHome
            text="Dividendos"
            icon={<MdAttachMoney size={60} color={colors.white} />}
            onPress={() => navigation('/dividends')}
          />
        </ContainerFlex>
      </ContainerRow>
      <div style={{ height: 20 }} />
      <ContainerRow>
        <ContainerFlex>
          <ButtonHome
            text="Transações"
            icon={<MdCompareArrows size={60} color={colors.white} />}
            onPress={() => navigation('/transactions')}
          />
        </ContainerFlex>
        <div style={{ width: 20 }} />
        <ContainerFlex>
          <ButtonHome
            text="Cotações"
            icon={<MdShowChart size={60} color={colors.white} />}
            onPress={() => navigation('/cotations')}
          />
        </ContainerFlex>
      </ContainerRow>

      <div style={{ height: 50 }} />
      <Button text="Logout" onPress={Logout} />
    </Container>
  );
}
