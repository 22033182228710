import styled from 'styled-components';

import { colors } from '../../commonStyle';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 60px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Label = styled.span`
  font-size: 12px;
  /* color: ${colors.white}; */
  color: ${colors.black};
`;

export const Input = styled.input`
  border: none;
  outline: none;
  /* background-color: ${colors.white30}; */
  /* background-color: ${(props) =>
    props.disabled ? colors.white50 : colors.white30}; */
  background-color: ${(props) => (props.disabled ? colors.grey : colors.white)};
  height: 60px;
  flex: 1;
  border-radius: 5px;
  padding: 10px 10px;
  font-size: 16px;
  /* color: ${colors.white}; */
  color: ${colors.black};
  align-self: stretch;
  margin-bottom: 10px;
  margin-top: 5px;
  border: 0.5px solid ${colors.black};
  //FIREFOX
  -moz-appearance: textfield;
  //Chrome, Safari, Edge, Opera
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Chrome, Safari, Edge, Opera
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox
  input[type=number] {
    -moz-appearance: textfield;
  } */
`;
