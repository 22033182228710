import React, { forwardRef } from 'react';
import { Container, Label, Input } from './styles';

function InputComponent(
  {
    label,
    type = 'text',
    value,
    onChange,
    disabled = false,
    onPress,
    maxLength,
    ...rest
  },
  ref
) {
  return (
    <Container disabled={disabled} onClick={onPress}>
      <Label>{label}</Label>
      <Input
        {...rest}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        maxLength={maxLength}
        disabled={disabled}
        ref={ref}
      />
    </Container>
  );
}

export default forwardRef(InputComponent);
