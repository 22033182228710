import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Container, Table, Tr, Th, Td } from '../TableList/styles';

export default function TableListSkeleton({ title = '', data = [1, 2, 3, 4] }) {
  return (
    <Container>
      <div style={{ height: 30 }} />
      <h1>{title}</h1>
      <div style={{ height: 5 }} />
      <Table>
        <Tr>
          <Th width="16%">Ativo</Th>
          <Th width="14%">Nome</Th>
          <Th width="14%">Cotação</Th>
          <Th width="14%">Data Cotação</Th>
          {/* <Th width="14%">Cotação Anterior</Th>
          <Th width="14%">Data Cotação Anterior</Th> */}
          <Th width="14%">Atualizado</Th>
        </Tr>
        {data.map((_, index) => (
          <Tr index={index}>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td>
            {/* <Td>
              <Skeleton />
            </Td>
            <Td>
              <Skeleton />
            </Td> */}
            <Td>
              <Skeleton />
            </Td>
          </Tr>
        ))}
      </Table>
    </Container>
  );
}
