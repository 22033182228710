import React from 'react';
import { useAuth } from '../context/auth';

import SignInRoutes from './SignInRoutes';
import SignedRoutes from './SignedRoutes';

export default function Routes() {
  const { signed } = useAuth();

  return signed ? <SignedRoutes /> : <SignInRoutes />;
}
