import { Container, Text } from './styles';

export default function Button({
  text,
  icon,
  color,
  disabled = false,
  onPress,
}) {
  return (
    <Container disabled={disabled} color={color} onClick={onPress}>
      {icon}
      <Text>{text}</Text>
    </Container>
  );
}
