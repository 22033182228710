import React from 'react';
import moment from 'moment';
import { MdArrowDownward, MdArrowUpward } from 'react-icons/md';

import { Container, Table, Tr, Th, Td } from './styles';
import { colors } from '../../../../commonStyle';

import { formatMoney } from '../../../../helpers';

export default function TableList({ title = '', data = [] }) {
  function cotationFormated(price, last_price) {
    return (
      <Td
      // color={
      //   parseFloat(price) > parseFloat(last_price)
      //     ? colors.primary
      //     : colors.red
      // }
      >
        {/* <div style={{ textAlign: 'left', position: 'absolute' }}>
          {getIconArrow(price, last_price)}
        </div> */}
        {formatMoney(price)}
      </Td>
    );
  }

  function getIconArrow(price, last_price) {
    return parseFloat(price) > parseFloat(last_price) ? (
      <MdArrowUpward size={18} color={colors.primary} />
    ) : (
      <MdArrowDownward size={18} color={colors.red} />
    );
  }

  return (
    <Container>
      <div style={{ height: 30 }} />
      <h1>{title}</h1>
      <div style={{ height: 10 }} />
      <Table>
        <Tr>
          <Th width="16%">Ativo</Th>
          <Th width="14%">Nome</Th>
          <Th width="14%">Cotação</Th>
          <Th width="14%">Data Cotação</Th>
          {/* <Th width="14%">Cotação Anterior</Th>
          <Th width="14%">Data Cotação Anterior</Th> */}
          <Th width="14%">Atualizado</Th>
        </Tr>
        {data.map((i, index) => (
          <Tr index={index}>
            <Td>{i.code}</Td>
            <Td>{i.name}</Td>
            {cotationFormated(i.price, i.last_price)}
            <Td>{moment(i.date_cotation).format('DD/MM/YYYY')}</Td>
            {/* <Td>{formatMoney(i.last_price)}</Td>
            <Td>
              {i?.last_date_cotation
                ? moment(i.last_date_cotation).format('DD/MM/YYYY')
                : null}
            </Td> */}
            <Td>{moment(i.updated_at).format('DD/MM/YYYY - HH:mm:ss')}</Td>
          </Tr>
        ))}
      </Table>
    </Container>
  );
}
