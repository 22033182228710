import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

// api.interceptors.request.use(async (config) => {
//   const token = localStorage.getItem('@investiments:token');

//   if (token) {
//     config.headers['x-access-token'] = `${token}`;
//   }

//   return config;
// });

export default api;
