import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Select from '../../components/Select';

import { Container, ContainerRow } from './styles';

import api from '../../services/api';

export default function EditCotation() {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [actives, setActives] = useState([]);

  const [activeId, setActiveId] = useState(null);
  const [priceCurrent, setPriceCurrent] = useState(null);
  const [price, setPrice] = useState(null);
  const [dateCotation, setDateCotation] = useState(
    moment().format('YYYY-MM-DD')
  );

  const priceRef = useRef();

  useEffect(() => {
    loadActives();
  }, []);

  async function loadActives() {
    setLoading(true);

    try {
      const response = await api.get('/active');

      const activesNamed = response.data?.map((item) => ({
        ...item,
        name: item.code,
        code: item.name,
      }));
      setActives(activesNamed);
    } catch (error) {
      toast.error('Erro ao buscar os ativos.');
      navigation(-1);
    } finally {
      setLoading(false);
    }
  }

  function clearFields() {
    setActiveId(null);
    setPriceCurrent(null);
    setPrice(null);

    document.getElementById('priceCurrent').value = null;
    document.getElementById('price').value = null;
  }

  function onChangeActive(id) {
    const activeFiltered = actives.filter((i) => i.id == id);

    if (activeFiltered.length > 0) {
      setPriceCurrent(activeFiltered[0].price);
      priceRef.current.focus();
    }
  }

  async function handleSubmit() {
    if (activeId == null) return toast.error('Necessário informar o ativo.');
    if (price == null || price == '')
      return toast.error('Necessário informar o valor da cotação.');

    setLoading(true);

    try {
      const response = await api.put(`/active/${activeId}`, {
        price,
        date_cotation: dateCotation
          ? moment(dateCotation).format('YYYY-MM-DD')
          : null,
      });

      const { msg } = response.data;
      toast.success(msg);
      clearFields();
    } catch (err) {
      toast.error(err.response.data.msg);
      clearFields();
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <br />
      <h1>Atualizar Cotações</h1>
      <div style={{ height: 10 }} />

      <ContainerRow>
        <Select
          label="Ativo:"
          items={actives}
          selected={activeId}
          onChange={(e) => {
            setActiveId(e);
            onChangeActive(e);
          }}
        />
        <div style={{ width: 20 }} />
        <Input
          id="date_cotation"
          type="date"
          label="Data da Cotação:"
          value={dateCotation}
          onChange={setDateCotation}
        />
      </ContainerRow>

      <Input
        id="priceCurrent"
        type="number"
        label="Cotação Atual (R$)"
        disabled
        value={priceCurrent}
      />

      <Input
        id="price"
        type="number"
        ref={priceRef}
        label="Nova Cotação (R$)"
        value={price}
        onChange={setPrice}
      />

      <br />
      <br />

      <ContainerRow>
        <Button text="Atualizar" onPress={handleSubmit} loading={loading} />
        <div style={{ width: 20 }} />
        <Button text="Voltar" onPress={() => navigation(-1)} />
      </ContainerRow>
    </Container>
  );
}
