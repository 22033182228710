import React, { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

import api from '../services/api';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [signed, setSigned] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storagedUser = localStorage.getItem('@invest:user');
    const storagedToken = localStorage.getItem('@invest:token');

    if (storagedToken && storagedUser) {
      setSigned(true);
      setUser(JSON.parse(storagedUser));
      api.defaults.headers['x-access-token'] = `${storagedToken}`;
    }
  }, []);

  async function Login(email, password) {
    try {
      const responseLogin = await api.post('/login', {
        email,
        password,
      });

      api.defaults.headers['x-access-token'] = `${responseLogin.data.token}`;

      localStorage.setItem('@invest:token', responseLogin.data.token);
      localStorage.setItem(
        '@invest:user',
        JSON.stringify(responseLogin.data.user)
      );

      setSigned(true);
      setUser(responseLogin.data.user);
    } catch (err) {
      toast.error(err.response.data.msg, { autoClose: false });
      //alert(err.response.data.msg);
    }
  }

  function Logout() {
    localStorage.removeItem('@invest:user');
    localStorage.removeItem('@invest:token');

    setSigned(false);
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: signed,
        user: user,
        Login,
        Logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
