import { Container, Label, Select, Option } from './styles';

const SelectComponent = ({
  label,
  items = [],
  selected,
  onChange,
  disabled,
  disabledNull = false,
}) => {
  return (
    <Container disabled={disabled}>
      <Label>{label}</Label>
      <Select selected={selected} onChange={(e) => onChange(e.target.value)}>
        {disabledNull === false && (
          <Option value={null} selected={selected == null}>{` - `}</Option>
        )}
        {items.map((i) => (
          <Option value={i.id} selected={i.id == selected}>
            {i.name}
          </Option>
        ))}
      </Select>
    </Container>
  );
};

export default SelectComponent;
