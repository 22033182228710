import React, { useState } from 'react';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content } from './styles';

import { useAuth } from '../../context/auth';

export default function Login() {
  const { Login } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleLogin(e) {
    e.preventDefault();

    setLoading(true);

    await Login(email, password);

    setLoading(false);
  }

  return (
    <Container>
      <Content>
        <h1>Meus Investimentos</h1>
        <div style={{ height: 10 }} />

        <Input label="Login:" value={email} onChange={setEmail} />

        <Input
          label="Senha:"
          type="password"
          value={password}
          onChange={setPassword}
        />

        <div style={{ height: 10 }} />
        <Button text="Acessar" onPress={handleLogin} loading={loading} />
      </Content>
    </Container>
  );
}
