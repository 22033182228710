import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Table, Tr, Th, Td } from './styles';
import { colors } from '../../../../commonStyle';

export default function TableListSkeleton({ data = [1, 2, 3, 4, 5] }) {
  return (
    <Table>
      <Tr>
        <Th width="5%">Item</Th>
        <Th width="20%">Ativo</Th>
        <Th width="20%">Quantidade</Th>
        <Th width="20%">Dividendo</Th>
        <Th width="15%">Data Pgto</Th>
        <Th width="25%">Total</Th>
      </Tr>
      {data.map((_, index) => (
        <Tr index={index}>
          <Td>
            <Skeleton />
          </Td>
          <Td>
            <Skeleton />
          </Td>
          <Td>
            <Skeleton />
          </Td>
          <Td>
            <Skeleton />
          </Td>
          <Td>
            <Skeleton />
          </Td>
          <Td>
            <Skeleton />
          </Td>
        </Tr>
      ))}
      <Tr index={data.length}>
        <Td>&nbsp;</Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
      </Tr>
      <Tr index={data.length + 1}>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td color={colors.red} bold>
          Total
        </Td>
        <Td color={colors.red} bold>
          <Skeleton />
        </Td>
      </Tr>
    </Table>
  );
}
