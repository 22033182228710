import React from 'react';
import moment from 'moment';

import { Table, Tr, Th, Td } from './styles';
import { colors } from '../../../../commonStyle';

import { formatMoney } from '../../../../helpers';

export default function TableList({ data = [], total = 0.0 }) {
  return (
    <Table>
      <Tr>
        <Th width="5%">Item</Th>
        <Th width="20%">Ativo</Th>
        <Th width="20%">Quantidade</Th>
        <Th width="20%">Dividendo</Th>
        <Th width="15%">Data Pgto</Th>
        <Th width="25%">Total</Th>
      </Tr>
      {data.map((i, index) => (
        <Tr index={index}>
          <Td>{index + 1}</Td>
          <Td>{i.code}</Td>
          <Td>{i.amount}</Td>
          <Td>{formatMoney(i.price)}</Td>
          <Td>
            {i.payment_date
              ? moment(i.payment_date).format('DD/MM/YYYY')
              : null}
          </Td>
          <Td>{formatMoney(i.total)}</Td>
        </Tr>
      ))}
      <Tr index={data.length}>
        <Td>&nbsp;</Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
      </Tr>
      <Tr index={data.length + 1}>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td></Td>
        <Td color={colors.red} bold>
          Total
        </Td>
        <Td color={colors.red} bold>
          {formatMoney(total)}
        </Td>
      </Tr>
    </Table>
  );
}
