import styled from 'styled-components';

import { colors } from '../../../../commonStyle';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const Th = styled.th`
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  background-color: ${colors.dark};
  color: ${colors.white};
`;

export const Tr = styled.tr`
  border: 1px solid #dddddd;
  background-color: ${(props) =>
    props.index % 2 == 0 ? colors.white : colors.grey}};
`;

export const Td = styled.td`
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  color: ${(props) => (props.color ? props.color : colors.black)}};
  ${(props) => props.bold && `font-weight: bold`};
`;
