import styled from 'styled-components';

import { colors } from '../../commonStyle';

export const Container = styled.div`
  display: flex;
  /* flex: 1; */
  width: 100%;
  flex-direction: column;
  min-height: 60px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Label = styled.span`
  font-size: 12px;
  /* color: ${colors.white}; */
  color: ${colors.black};
`;

export const Select = styled.select`
  border: none;
  outline: none;
  /* background-color: ${colors.white30}; */
  background-color: ${colors.white};
  height: 60px;
  flex: 1;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  /* color: ${colors.white30}; */
  color: ${colors.black};
  align-self: stretch;
  margin-bottom: 10px;
  margin-top: 5px;
  border: 1px solid ${colors.black};
`;

export const Option = styled.option`
  height: 60px;
  flex: 1;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  color: ${colors.black};
  align-self: stretch;
  margin-bottom: 10px;
  margin-top: 5px;
`;
